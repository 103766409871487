import service from "@/api/service";
import {
  GetRequest,
  SearchRequest,
  WinRequest
} from "@/api/ticket-lottery/request";
import { GetResponse, SearchResponse } from "@/api/ticket-lottery/response";
import { CommonResponse } from "@/api/response";

/**
 * チケット抽選管理一覧検索APIをコールします。
 *
 * @param searchRequest チケット抽選管理一覧検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-ticket-lottery", searchRequest);
  return response.data as SearchResponse;
}

/**
 * チケット抽選管理チケットタイトル取得APIをコールします。
 *
 * @param getRequest チケット抽選管理チケットタイトル取得のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-ticket-lottery", getRequest);
  return response.data as GetResponse;
}

/**
 * 当選確定APIをコールします。
 *
 * @param winRequest 当選確定のリクエストボディ
 * @return CommonResponse
 */
export async function win(winRequest: WinRequest) {
  const response = await service.post("/win-ticket-lottery", winRequest);
  return response.data as CommonResponse;
}
