import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/ticket-lottery/response";
import { SearchRequest } from "@/api/ticket-lottery/request";
import * as TicketLotteryAPI from "@/api/ticket-lottery";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "ticket-lottery/search";

/**
 * プッシュメッセージ一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem, private lotteryType: number) {}
  get entryId() {
    return this._item.entryId;
  }
  get userId() {
    return this._item.userId;
  }
  get userName() {
    return this._item.userName;
  }
  get title() {
    return this._item.title;
  }
  get winFlg() {
    return this._item.winFlg;
  }
  get winFlgName() {
    switch (this.lotteryType) {
      case 1:
        // 自動抽選
        switch (this._item.winFlg) {
          case 0:
            return "抽選前";
          case 1:
            return "当選";
          case 2:
            return "落選";
          case 3:
            return "落選(決済エラー)";
        }
        break;
      case 2:
        // 手動抽選
        switch (this._item.winFlg) {
          case 0:
            return "当選";
          case 1:
            return "当選";
          case 2:
            return "落選";
          case 3:
            return "落選(決済エラー)";
          default:
            break;
        }
        break;
    }
    return "不明";
  }
}

/**
 * チケット抽選管理一覧検索API（/search-ticket-lottery）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.items || []).map(
        i => new ListItem(i, this.getLotteryType)
      );
    } else {
      return [] as ListItem[];
    }
  }

  get getWinLimit() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.winLimit || 0;
    } else {
      return 0;
    }
  }

  get getWinCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.winCount || 0;
    } else {
      return 0;
    }
  }

  get getWinRemain() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.winRemain || 0;
    } else {
      return 0;
    }
  }

  get getLotteryType() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.lotteryType || 1;
    } else {
      return 1;
    }
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg || 0;
    } else {
      return 0;
    }
  }

  get getActionFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.actionFlg || 0;
    } else {
      return 0;
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await TicketLotteryAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
