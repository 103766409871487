import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { SendRequest } from "@/api/lottery-push-ticket/request";
import * as LotteryPushTicketAPI from "@/api/lottery-push-ticket";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "lottery-push-ticket/send";

/**
 * 当選者プッシュ通知登録更新API（/lottery-push-ticket）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Send extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async send(sendRequest: SendRequest) {
    const commonResponse = await LotteryPushTicketAPI.send(sendRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clear() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Send);
