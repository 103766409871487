import service from "@/api/service";
import { SendRequest } from "@/api/lottery-push-ticket/request";
import { CommonResponse } from "@/api/response";

/**
 * 当選者プッシュ通知登録更新APIをコールします。
 *
 * @param sendRequest 当選者プッシュ通知登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function send(sendRequest: SendRequest) {
  const response = await service.post("/lottery-push-ticket", sendRequest);
  return response.data as CommonResponse;
}
