import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { GetRequest } from "@/api/ticket-lottery/request";
import { SearchRequest } from "@/api/ticket-lottery/request";
import { WinRequest } from "@/api/ticket-lottery/request";
import { SendRequest } from "@/api/lottery-push-ticket/request";
import TicketLotteryGet from "@/store/ticket-lottery/get";
import TicketLotterySearch from "@/store/ticket-lottery/search";
import TicketLotteryWin from "@/store/ticket-lottery/win";
import LotteryPushTicketSend from "@/store/lottery-push-ticket/send";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import Loading from "@/store/loading";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDatePicker }
})
export default class Index extends Vue {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "チケット抽選管理";
  headingSub = "Ticket lottery management";
  breadCrumbs = [
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "ticket-lottery-management" }
    },
    { text: "詳細情報", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "会員ID", value: "userId" },
    { text: "エントリーID", value: "entryId" },
    { text: "購入者名", value: "userName" },
    { text: "チケットタイトル", value: "title" },
    { text: "", value: "space", sortable: false },
    { text: "当選ステータス", value: "custom" }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // 検索入力オプション
  inputOptions = {} as SearchRequest;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  isPriseDialogShowing = false;
  isPushDialogShowing = false;

  searchButtonClicked = false;

  selectingEntryIds = [] as string[];

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return TicketLotterySearch.getItems;
  }

  get winLimit() {
    return TicketLotterySearch.getWinLimit;
  }

  get winCount() {
    return TicketLotterySearch.getWinCount;
  }

  get winRemain() {
    return TicketLotterySearch.getWinRemain;
  }

  get lotteryType() {
    return TicketLotterySearch.getLotteryType;
  }

  get writeFlg() {
    return TicketLotterySearch.getWriteFlg;
  }

  get actionFlg() {
    return TicketLotterySearch.getActionFlg;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return TicketLotterySearch.getTotalCount;
  }

  get ticketItems() {
    return TicketLotteryGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await TicketLotterySearch.restore(this.inputOptions as SearchRequest);
    const request = TicketLotterySearch.getSearchRequest;
    // 検索入力オプション
    request.userId && (this.inputOptions.userId = request.userId);
    request.ticketId && (this.inputOptions.ticketId = request.ticketId);

    // 検索テーブルオプション
    this.tableOptions.sortKey = "userId";
    await this.fetchTicketList();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TicketLotteryGet.clearResponse();
    await TicketLotterySearch.clearResponse();
    await TicketLotteryWin.clear();
    await LotteryPushTicketSend.clear();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.searchButtonClicked = true;
    await Flash.clear();
    await this.search();
  }

  /**
   * 当選一括確定ボタンが押下された際のコールバック
   */
  openWinDialogClickCallback() {
    this.isPriseDialogShowing = true;
  }

  /**
   * 当選者プッシュ通知ボタンが押下された際のコールバック
   */
  openPushDialogClickCallback() {
    this.isPushDialogShowing = true;
  }

  async winClickCallback() {
    await Flash.clear();
    this.isPriseDialogShowing = false;
    await TicketLotteryWin.win(this.createWinRequest());
    if (TicketLotteryWin.isSuccess) {
      await Flash.setSuccessNow({
        message: "当選を確定させました。",
        consumePath: ""
      } as SuccessAlert);
    } else {
      await Flash.setErrorNow({
        message: TicketLotteryWin.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }

    await this.search();
  }

  async pushClickCallback() {
    await Flash.clear();
    this.isPushDialogShowing = false;
    await LotteryPushTicketSend.send(this.createSendRequest());
    if (LotteryPushTicketSend.isSuccess) {
      await Flash.setSuccessNow({
        message: "当選者にプッシュ通知を送信しました。",
        consumePath: ""
      } as SuccessAlert);
    } else {
      await Flash.setErrorNow({
        message: LotteryPushTicketSend.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return;
    }

    await this.search();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchTicketList(): Promise<boolean> {
    if (TicketLotteryGet.isSuccess) {
      return true;
    }

    await TicketLotteryGet.get({ id: this.id } as GetRequest);
    if (!TicketLotteryGet.isSuccess) {
      await Flash.setErrorNow({
        message: TicketLotteryGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return TicketLotteryGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    let isSuccess = await this.fetchTicketList();
    if (!isSuccess) {
      return;
    }

    this.selectingEntryIds = [];

    let request = this.createSearchRequest();
    await TicketLotterySearch.search(request);
    if (!TicketLotterySearch.isSuccess) {
      await Flash.setErrorNow({
        message: TicketLotterySearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createSearchRequest(): SearchRequest {
    const request = { shopId: this.id, ...this.tableOptions } as SearchRequest;

    if (this.inputOptions.userId) {
      request.userId = this.inputOptions.userId;
    } else {
      delete request.userId;
    }

    if (this.inputOptions.ticketId) {
      request.ticketId = this.inputOptions.ticketId;
    } else {
      delete request.ticketId;
    }

    // 20201223 C59_レポート-チケット抽選管理詳細情報一覧画面(質問管理表_No.13) presq ishida start
    if (this.tableOptions.sortKey == 'custom') {
      request.sortKey = 'winFlg'
    }
    // 20201223 C59_レポート-チケット抽選管理詳細情報一覧画面(質問管理表_No.13) presq ishida end

    return request;
  }

  private createWinRequest() {
    return {
      shopId: this.id,
      ticketId: this.inputOptions.ticketId,
      entryIds: this.selectingEntryIds
    } as WinRequest;
  }

  private createSendRequest() {
    return {
      shopId: this.id,
      ticketId: this.inputOptions.ticketId
    } as SendRequest;
  }
}
